<template>
  <ion-page id="mission">
    <WhiteHeader
      :back-button="true"
      route-back="/planning"
      title="Détails d'une mission"
    />
    <ion-content class="ion-padding">
      <ion-refresher
        id="refresher-detail"
        slot="fixed"
        @ionRefresh="doRefresh($event)"
        @click="doRefresh($event)"
        ><ion-refresher-content></ion-refresher-content
      ></ion-refresher>

      <DayPart :part="mission.day_part"></DayPart>
      <Date v-if="humanStart" :start="humanStart" :end="humanEnd" />
      <hr class="color-hr" />

      <Equipment
        :machine_name="mission.machine_name"
        :machine_category="mission.machine_category"
        :machine_number="mission.machine_number"
        :machine_translation="mission.machine_translation"
        :machine_poids="mission.machine_poids"
        :tools="mission.tools"
        :voiture_pilote="missionItem.voiture_pilote"
        :attachments="mission.machine_attachments"
      />
      <hr class="color-hr" />
      <Transfer
        :machines="missionItem.machines"
        :machines_manual="missionItem.machines_manual"
        :tools="missionItem.tools"
        :tools_manual="missionItem.tools_manual"
      />
      <hr class="color-hr" />
      <ion-grid>
        <ion-row>
          <ion-col class="mt-15 column">
            <ion-note>Réf chantier :</ion-note>
            <ion-label v-if="missionItem.worksite_reference">{{ missionItem.worksite_reference }}</ion-label>
            <ion-label v-else class="text-red">Aucune</ion-label>
          </ion-col>
          <ion-col class="mt-15 column">
            <ion-note>N° commande :</ion-note>
            <ion-label v-if="missionItem.order_number">{{ missionItem.order_number }}</ion-label>
            <ion-label v-else class="text-red">Aucun</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
      <hr class="color-hr" />
      <ion-label class="bold title-detail-mission"
        >Chargement<ion-badge
          v-if="
            missionItem.coupon_company &&
            missionItem.coupon_company === 'source'
          "
          color="success"
          class="ml-10"
          >Client</ion-badge
        ></ion-label
      >
      <information
        :name-company="missionItem.source"
        :manager="missionDetail.source_contact"
        :manager_phone="missionDetail.source_contact_phone"
        :worksite_city="missionItem.source_location_city"
        :worksite_name="missionItem.source_location_name"
        :worksite_address="missionItem.source_location_address"
        :worksite_coordinates="missionItem.source_coordinates"
        :instructions="missionItem.source_instructions"
        :need_signature_source="missionItem.need_signature_source"
        ref="sourceMap"
      />
      <hr class="color-hr" />
      <ion-label class="bold title-detail-mission"
        >Déchargement<ion-badge
          v-if="
            missionItem.coupon_company &&
            missionItem.coupon_company === 'target'
          "
          color="success"
          class="ml-10"
          >Client</ion-badge
        ></ion-label
      >
      <information
        :name-company="missionItem.target"
        :manager="missionDetail.target_contact"
        :manager_phone="missionDetail.target_contact_phone"
        :worksite_city="missionItem.target_location_city"
        :worksite_name="missionItem.target_location_name"
        :worksite_address="missionItem.target_location_address"
        :worksite_coordinates="missionItem.target_coordinates"
        :instructions="missionItem.target_instructions"
        :attachments="missionItem.attachments"
        ref="targetMap"
      />
      <hr class="color-hr" />
      <liste-rental-coupon
        :rental-coupons="missionDetail.rental_coupons"
        type-mission="transfert"
      />
      <new-rental-coupon
        v-if="missionItem.pricing_information"
        type-mission="transfert"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonBadge,
  toastController,
  IonRow,
  IonCol,
  IonGrid,
  IonNote,
} from '@ionic/vue';
import {
  forceRefreshDetailMission,
  forceRefreshPlanning,
  getDetailMission,
  getMissionOrder,
} from '@/services/driver/missionsOrders';
import { convertDateToReadableDate } from '@/services/utils/dateUtils';
import Equipment from '@/components/driver/detailMission/Equipment';
import Information from '@/components/driver/detailMission/Information';
import Transfer from '@/components/driver/detailMission/Transfer';
import Date from '@/components/driver/detailMission/Date';
import DayPart from '@/components/driver/card/detailCardPlanning/DayPart.vue';
import ListeRentalCoupon from '@/components/driver/detailMission/ListeRentalCoupon';
import NewRentalCoupon from '@/components/driver/detailMission/NewRentalCoupon';
import WhiteHeader from '@/components/driver/header/WhiteHeader';

export default {
  name: 'TransfertDetail',
  components: {
    WhiteHeader,
    NewRentalCoupon,
    ListeRentalCoupon,
    Date,
    DayPart,
    Transfer,
    Information,
    Equipment,
    IonPage,
    IonContent,
    IonLabel,
    IonRefresher,
    IonRefresherContent,
    IonBadge,
    IonRow,
    IonCol,
    IonGrid,
    IonNote,
  },
  data() {
    return {
      mission: {},
      missionItem: {},
      missionDetail: {},
      refreshInterval: null,
    };
  },

  // Launch auto-refresh when page gets displayed
  ionViewDidEnter() {
    this.doRefresh();

    this.refreshInterval = setInterval(() => {
      this.doRefresh();
    }, 60 * 1000);

    setTimeout(() => {
      this.$refs.sourceMap.initMap();
      this.$refs.targetMap.initMap();
    }, 0);
  },

  ionViewWillLeave() {
    this.$refs.sourceMap.removeMap();
    this.$refs.targetMap.removeMap();
  },

  // Stop auto-refresh when page quits
  ionViewDidLeave() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },

  mounted() {
    const { id, idRide, idItem } = this.$route.params;

    Promise.all([
      getMissionOrder(id, idRide, 'transfert'),
      getDetailMission('transfert', id, idItem),
    ]).then(([mission, missionDetail]) => {
      if (!mission) {
        this.backPlanning();
        return;
      }

      this.mission = mission;
      this.missionDetail = missionDetail;

      for (const transfer in this.mission['transport_items']) {
        if (transfer === idItem) {
          this.missionItem = this.mission['transport_items'][transfer];
        }
      }

      // Init maps just in case
      this.$nextTick(() => {
        this.$refs.sourceMap.initMap();
        this.$refs.targetMap.initMap();
      });
    });
  },

  methods: {
    doRefresh(event) {
      forceRefreshPlanning();

      if (!this.$route || !this.$route.params || !this.$route.params.id) return;
      const { id, idRide, idItem } = this.$route.params;

      Promise.all([
        getMissionOrder(id, idRide, 'transfert'),
        forceRefreshDetailMission('transfert', id, idItem),
      ])
        .then(([mission, missionDetail]) => {
          if (!mission) {
            this.backPlanning();
            return;
          }

          this.mission = mission;

          for (const transfer in this.mission['transport_items']) {
            if (transfer === idItem) {
              this.missionItem = this.mission['transport_items'][transfer];
            }
          }

          this.missionDetail = missionDetail;
        })
        .catch(() => {
          console.log('Unable to force-refresh transfer detail.');
        })
        .finally(() => {
          if (event && event.target && event.target.complete) {
            event.target.complete();
          }
        });
    },

    async backPlanning() {
      const toast = await toastController.create({
        header: 'Changement',
        message: 'La mission ne vous est plus affectée',
        duration: 3000,
        color: 'success',
        position: 'top',
        cssClass: 'toast-rental-coupon',
      });
      await toast.present();

      this.$router.push('/planning');
    },
  },

  computed: {
    humanStart() {
      if (this.mission && this.mission.start) {
        return convertDateToReadableDate(this.mission.start);
      }
      return '';
    },

    humanEnd() {
      if (this.mission && this.mission.end) {
        return convertDateToReadableDate(this.mission.end);
      }
      return '';
    },
  },
};
</script>
