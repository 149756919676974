<template>
  <div
    class="fab-custom"
    @click="goToGoodPage()"
  >
    <ion-icon :icon="createOutline" />
    <label>Créer un BL</label>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { createOutline } from 'ionicons/icons';

export default {
  name: 'NewRentalCoupon',
  components: {
    IonIcon,
  },
  props: {
    typeMission: String,
  },
  setup() {
    return {
      createOutline,
    };
  },
  methods: {
    goToGoodPage() {
      let link = '/';
      if (this.typeMission == 'reservation') {
        link += 'chantier/' + this.$route.params.id;
        link +=  '/' + this.$route.params.idRide;
      } else {
        link += this.typeMission + '/' + this.$route.params.id + '/' + this.$route.params.idRide + '/' + this.$route.params.idItem;
      }

      link +=  '/bonLocation/create';

      this.$router.push(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.fab-custom {
  position: sticky;
  // do not remove this version because we need it for safari
  position: -webkit-sticky;
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcd34d;
  border-radius: 10px;
  padding: 16px 24px 16px 24px;
  bottom: 0;
  right: 0;
  z-index: 1100;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);

  ion-icon {
    margin-right: 1rem;
    width: 18px;
    height: 18px;
  }

  label {
    font-size: 14px;
  }
}
</style>
