<template>
  <div>
    <div
      class="flex"
      v-for="(attachment, key) in attachments"
      :key="attachment.id || attachments.randomId"
    >
      <ion-item v-show="key === 0 || showMore" lines="none" class="w-100">
        <ion-img
          slot="start"
          :src="getAttachmentIconPath(attachment.extension)"
        ></ion-img>

        <ion-label
          v-if="!attachment.editing"
          @click="displayAttachment(attachment)"
        >
          {{ attachment.newName || attachment.name }}
        </ion-label>

        <ion-input
          v-else
          :value="
            attachment.newName
              ? attachment.newName.slice(0, attachment.newName.lastIndexOf('.'))
              : attachment.name.slice(0, attachment.name.lastIndexOf('.'))
          "
          @change="temporaryName = $event.target.value"
          fill="solid"
        />

        <!-- v-show because we need popover button in the DOM to make popover work-->
        <ion-button
          v-if="status !== 'send' || typeVue === 'create' || filesTooBig"
          v-show="!attachment.editing"
          slot="end"
          :id="attachment.id || attachment.randomId"
          size="small"
          color="dark"
          fill="clear"
        >
          <ion-icon
            class="popover-toggle"
            slot="icon-only"
            :icon="ellipsisVertical"
          ></ion-icon>
        </ion-button>

        <ion-button
          v-show="attachment.editing"
          slot="end"
          fill="clear"
          color="success"
          size="small"
          @click="onAttachmentNameInputChange(attachment)"
        >
          <ion-icon slot="icon-only" :icon="checkmark"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-popover
        v-if="status !== 'send' || typeVue === 'create' || filesTooBig"
        :trigger="attachment.id || attachment.randomId"
        :dismiss-on-select="true"
      >
        <ion-content>
          <ion-list lines="none">
            <ion-item
              @click="
                attachment.editing = true;
                $forceUpdate();
              "
              :button="true"
            >
              <ion-icon
                color="dark"
                slot="start"
                :icon="createOutline"
              ></ion-icon>
              <ion-label>Modifier</ion-label>
            </ion-item>

            <ion-item @click="deleteAttachment(attachment)" :button="true">
              <ion-icon
                color="danger"
                slot="start"
                :icon="trashOutline"
              ></ion-icon>
              <ion-label color="danger">Supprimer</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-popover>
    </div>

    <ion-row
      v-if="attachments && attachments.length > 1"
      class="ion-justify-content-center show-more"
    >
      <ion-button size="small" fill="outline" @click="showMore = !showMore">
        {{ showMore ? 'Voir moins' : 'Voir plus' }}
        <ion-icon
          slot="end"
          class="show-more-icon"
          :class="{ expanded: showMore }"
          :icon="chevronDown"
        ></ion-icon>
      </ion-button>
    </ion-row>

    <input
      ref="files"
      type="file"
      multiple="true"
      accept=".doc,.docx,.odt,.pdf,.png,.jpg,.jpeg,.bmp,.webp,.mp4,.mkv,.avi,.mov,.3gp"
      hidden
      @change="onFileInputChange"
    />

    <ion-row
      v-if="status !== 'send' || typeVue === 'create'"
      class="ion-justify-content-center"
    >
      <ion-button
        color="dark"
        fill="clear"
        class="add-attachment"
        @click="$refs.files.click()"
      >
        <ion-icon slot="end" :icon="addOutline"></ion-icon>
        Ajouter un document
      </ion-button>
    </ion-row>
  </div>
</template>

<script>
import {
  IonRow,
  IonImg,
  IonLabel,
  IonIcon,
  IonButton,
  IonItem,
  IonPopover,
  IonList,
  IonInput,
  toastController,
} from '@ionic/vue';
import {
  chevronDown,
  addOutline,
  createOutline,
  ellipsisVertical,
  checkmark,
  trashOutline,
} from 'ionicons/icons';
import utils from '@/services/utils/Utils';
import axios from 'axios';
import { getParams } from '@/services/users';

export default {
  components: {
    IonRow,
    IonImg,
    IonLabel,
    IonIcon,
    IonButton,
    IonItem,
    IonList,
    IonPopover,
    IonInput,
  },

  props: {
    attachments: Array,
    waitingFiles: Array,
    attachmentsToDelete: Array,
    attachmentsToUpdate: Object,
    kind: String,
    typeVue: String,
    status: String,
    filesTooBig: Boolean,
  },

  emits: [
    'update:attachments',
    'update:waitingFiles',
    'update:attachmentsToDelete',
    'update:attachmentsToUpdate',
  ],

  setup() {
    return {
      chevronDown,
      addOutline,
      createOutline,
      ellipsisVertical,
      checkmark,
      trashOutline,
    };
  },

  data() {
    return {
      showMore: false,
      eventKind: null,
      temporaryName: '',
    };
  },

  methods: {
    getAttachmentIconPath: utils.getAttachmentIconPath,

    async displayAttachment(attachment) {
      // avoid trying to open waiting file
      if (!attachment.id) return;

      const response = await axios.get(
        '/attachment/' + attachment.id,
        await getParams(),
      );

      const toast = await toastController.create({
        buttons: [
          {
            text: 'Cliquez ici',
            handler: () => {
              window.open(response.data, '_blank');
            },
          },
        ],
        message: 'Votre fichier est prêt !',
        duration: 15000,
        color: 'primary',
        position: 'bottom',
      });

      await toast.present();
    },

    onFileInputChange(event) {
      const newFiles = [...this.attachments, ...event.target.files].map(a => {
        a.kind = this.kind;
        a.extension = a.name.substring(a.name.lastIndexOf('.') + 1);
        a.randomId = (Math.random() + 1).toString(36).substring(2);

        return a;
      });

      this.$emit('update:attachments', newFiles);
      this.$emit('update:waitingFiles', [
        ...this.waitingFiles,
        ...event.target.files,
      ]);
    },

    deleteAttachment(attachment) {
      const newFiles = this.attachments;
      newFiles.splice(newFiles.indexOf(attachment), 1);

      if (!attachment.id) {
        const newWaitingFiles = this.waitingFiles;
        newWaitingFiles.splice(newWaitingFiles.indexOf(attachment), 1);

        this.$emit('update:waitingFiles', newWaitingFiles);
      } else {
        this.$emit('update:attachmentsToDelete', [
          ...this.attachmentsToDelete,
          attachment.id,
        ]);
      }

      this.$emit('update:attachments', newFiles);
    },

    onAttachmentNameInputChange(attachment) {
      if (!this.temporaryName) {
        attachment.editing = false;
        this.$forceUpdate();
        return;
      }

      const fullNewName = `${this.temporaryName}.${attachment.extension}`;

      if (attachment.id) {
        const newAttachmentsToUpdate = this.attachmentsToUpdate;
        newAttachmentsToUpdate[attachment.id] = this.temporaryName;

        this.$emit('update:attachmentsToUpdate', newAttachmentsToUpdate);
      } else {
        const newFiles = this.waitingFiles.map(a => {
          if (a.randomId === attachment.randomId) {
            a.newName = fullNewName;
          }

          return a;
        });

        this.$emit('update:waitingFiles', newFiles);
      }

      attachment.editing = false;
      attachment.newName = fullNewName;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="scss">
.add-attachment {
  text-transform: none;
}

ion-item {
  margin-top: 0.5rem;

  ion-img {
    max-width: 32px;
    height: 32px;
  }

  ion-label {
    font-size: 12px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

ion-input {
  --background: var(--ion-color-light);
  --border-radius: 4px;
}

.w-100 {
  width: 100%;
}
</style>
